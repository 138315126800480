@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#kenapa-pilih {
  .sec-title {
    font-weight: 700;
    margin-bottom: 14px;
    padding-bottom: 32px;
  }
  .title {
    font-weight: 700;
    padding-top: 30px;
    padding-bottom: 8px;
  }
  .content-padding {
    @media only screen and (max-width: 480px) {
      margin: 35px 0;
    }
  }
}
