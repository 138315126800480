@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

.dropdown-container {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.text-filter {
  font-weight: 400 !important;
}

.input-date {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  width: 116px;
  height: 42px;
  cursor: pointer;
  background-color: transparent;
  padding-left: 12px;

  &:focus {
    box-shadow: unset;
    border-color: #ced4da;
  }
}

.input-date:focus {
  border: 1px solid #ff7a00 !important;
}

.input-date:active {
  border: 1px solid #ff7a00 !important;
}

.title-history {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 8px 0;
}

.filter-text {
  font-size: 12px !important;
  padding: 4px 32px 4px 16px !important;
  font-weight: 600 !important;
  color: #cb3a31 !important;
}

.active-item-menu {
  color: #ff7a00 !important;
  text-align: right !important;
  background-color: transparent !important;
}

.filter-text:hover {
  background-color: transparent !important;
}

.filter-text:active {
  background-color: #ff7a00 !important;
  opacity: 0;
}

.menu-dropdown {
  top: 10px !important;
  left: -120px !important;
  padding: 0px !important;
  transform: translate3d(0px, 38px, 0px) !important;
  padding: 8px 0 !important;
}

.active-item {
  background: #fffaf4 !important;
  color: #ff7a00 !important;
  font-weight: 600 !important;
  border-radius: 8px !important;
}