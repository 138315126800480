// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Sweetalert
// @import '~sweetalert2/src/sweetalert2.scss';

// Calendar
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
// @import '~@fullcalendar/list/main.css';
