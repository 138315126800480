@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

navigation {
    overflow: auto;
  
    .nav {
      white-space: nowrap;
      flex-wrap: nowrap;
      min-width: min-content;
    }
    
    .nav-tabs .nav-link {
      color: $secondary;
      border: unset;
      text-align: center;
      padding: 1.25rem 1rem;
      font-weight: 600;
      opacity: .7;
      @include transition(opacity 120ms linear);
      @include media-breakpoint-up(md) {
        min-width: 150px;
      }
    
      &:hover {
        border: unset;
        opacity: .9;
      }
    }
    
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      color: $primary;
      position: relative;
      opacity: 1;
    
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        background-color: $primary;
        border-radius: 13px 13px 0 0;
      }
    }
  }

  