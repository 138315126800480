@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";


section#calculator {

    .text-title {
        font-weight: 700;
        line-height: 1.4;
        margin-bottom: 24px;
        text-align: center;
        padding: 0;
    }

    .text {
        font-size: 1.1rem;
        color: #8E8E93;
        line-height: 1.6;
        text-align: center;
    }

    .box-container {
        width: 630px;
        background: #FFFFFF;
        padding: 24px 32px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        margin: 52px 0;
    }

    .text-label {
        font-size: 12px;
        color: #000000;
    }

    .select {
        height: 42px;
    }

    .input-cal:focus {
        border: 2px solid rgba(255, 122, 0, 0.2);
        border-left: none;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .active-border {
        border: 2px solid rgba(255, 122, 0, 0.2);
        border-right: none;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .menu-dropdown-cal {
        padding: 8px 0 !important;
        width: 100%;
    }

    .dropdown-container-cal {
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        width: 100%;
    }

    .text-filter-cal {
        font-weight: 400 !important;
        width: 100% !important;
        text-align: left !important;
    }

    .input-container-cal {
        background: #F5F5F5;
        border: 1px solid #C2C2C2;
        border-radius: 6px;
        width: 100%;
        height: 41px;
        box-sizing: border-box !important;
        padding: 8px 12px;
        color: #757575;
    }

    .text-note {
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #404040;
        opacity: 0.6;
        margin: 4px 0 0 0;
    }
    .text-error {
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: red;
        opacity: 0.6;
        margin: 6px 0 0 0;
    }

    .box-container-total {
        height: 80px;
        width: 100%;
        background: #FFF9F2;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        gap: 24px;
        position: relative;

    }

    .box-container-total-borrower {
        height: 80px;
        width: 100%;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        gap: 24px;
        position: relative;
    }

    .text-total {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: -0.006em;
        color: #0A0A0A;
        opacity: 0.6;
        margin: 0;
        margin-top: 12px;
    }

    .image-logo-cal {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0.25;
    }

    .result {
      color: #0A0A0A;
      opacity: 0.6;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

}