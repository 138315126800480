@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#event {
  .event-header {
    background-color: $primary;
    border-radius: $border-radius;
    color: #fff;
    padding: 80px 60px;
    margin-bottom: 60px;

    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }

    h4 {
      font-weight: 400;
    }
  }

  .sec-title {
    font-weight: 700;
    margin-bottom: 14px;
  }

  .box {
    border-radius: 12px;
    padding: 10px 40px;
    background-image: linear-gradient(-90deg, rgba(252, 250, 245, 0.61), #FF7A00);
    margin: 10px 0;

    @media only screen and (max-width : 480px) {
      margin: 10px 15px;

    }
  }

  .location {
    padding: 4px 35px;
    border-radius: 10px;
    position: initial;
    display: table-cell;
    top: 80px;
    background-color: #ffcda1;
  }

}

.header-image {
  width: 100%;
  height: 379px;
  background-position: center;
  background-size: contain;
  border-radius: 16px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4rem;

  .title-head {
    font-weight: 700;
    font-size: 32px;
    line-height: 41px;
    align-items: center;
    color: #FFFFFF;
  }

  .text-head {
    font-weight: 400;
    font-size: 24px;
    line-height: 41px;
    align-items: center;
    color: #FFFFFF;
  }

  .title-paragraf {
    font-weight: 700;
    font-size: 29.3333px;
    line-height: 41px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    width: 70%;
  }

  @media only screen and (max-width: 480px) {
    padding: 2.2em 1.1em;
    height: auto;
    background-size: cover;

    .title-head {
      font-size: 24px;
      line-height: 21px;
    }

    .text-head {
      font-size: 18px;
      line-height: 21px;
    }

    .title-paragraf {
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
    }
  }
}