@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

.section-blog{
    padding: 75px 0;
    p{
        font-size: 1.1rem;
        color: #8E8E93;
        line-height: 1.6;
    }
}

.terkait-p{
    color: $secondary;
    // height: 88px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.blog-header {
    background-color: $primary;
      border-radius: $border-radius;
      color: #fff;
      padding: 80px 60px;
    //   margin-bottom: 60px;
  
      h2 {
        font-weight: 700;
        margin-bottom: 10px;
      }
  
      h4 {
        font-weight: 400;
      }
  }

.category-menu{
    list-style: none;
    padding-left: 0;
}

.category-card{
    transform: translateY(-50%);

    @media only screen and (max-width : 480px) {
        transform: translateY(25%);

    }
}
.menu{
    display: inline-flex;
    margin: 0 10px 0 10px;
}

.content{
    margin: 50px 0 50px 0;
    .judul{
        @media only screen and (max-width : 480px) {
            padding-top: 20px;
        }
    }
    .identity{
        display: flex;
        align-items: center;
        color: black;
        margin-bottom: 12px;
        font-size: 0.9rem;
        font-weight: 600;
    }
    .bulan{
        font-weight: 700;
        font-size: 1.2rem;
        letter-spacing: 1.5px;
        
    }
    .hr-bulan{
        height:2.5px; 
        border:none; 
        color:#000; 
        background-color:#000; 
        width:30%; 
        text-align:left; 
        margin: 15px auto 15px 0px;
    }
    .tanggal{
        font-weight: 800;
        font-size: 2.5rem;
    }
    .name-category{
        display: table;
        position: absolute;
        top: 0;
        background-color: darkgray;
        opacity: 90%;
        width: 130px;
        height: 30px;
        text-align:center;
        font-size: 14px;
        font-weight: bold;
        color: black;
        padding: 10px 10px 10px 10px
    }
    .img-content{
        width:100%;
        height:270px;
    }
    .dot{
        padding-left: 5px;
        padding-right: 5px;
    }
}

.main-header{
    width: 100%;
    min-height: 500px;
    background-position: center center;
    background-size: cover;
    padding: 40px 0px;
}

.blog-detail{
    margin: 30px 0;

    p{
        font-size: 1.1rem;
    }
    .category{
        color: $primary;
        font-weight: 800;
        margin: 20px 0
    }

    .title-detail{
        font-weight: bolder;
        margin-bottom: 35px;
    }

    .identity-detail{
        display: inline-flex;
        font-size: 0.95rem;
    }

    .by-detail{
        padding: 0 5px 0 30px;
    }

    .img-detail{
        width: 100%;
        height: 300px;
        object-fit: cover;

        @media only screen and (max-width : 480px) {
            width: 100%;
        }
    }
}