section#kebijakan{
    padding: 60px 0;
  
    .text-title {
      font-weight: 700;
      line-height: 1.4;
      max-width: 700px;
      margin-bottom: 30px;
    }
    .text {
      font-size: 1.1rem;
      color: #8E8E93;
      max-width: 970px;
      line-height: 1.6;
    }
    
    .sub-text {
      font-size: 1.1rem;
      color: #8E8E93;
      max-width: 970px;
      line-height: 1.6;
      margin-left: 34px;
      margin-top: 10px;
    }


    .text-section{
      font-weight: 800;
      line-height: 1.4;
      max-width: 700px;
      margin-bottom: 10px;
    }

    .jarak{
      padding-bottom: 30px !important;
    }
  
  }