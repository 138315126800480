@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../sass/variables";
@import "../sass/mixins";

$headerHeight: 108.38px;
$headerHeightExpanded: 128.38px;
$headerHeightCollapsed: 71.38px;

@include media-breakpoint-up(lg) {
    section.with-pad {
        padding: 80px 0;
    }

    section.welcome {
        padding: 60px 0;
    }

    .col-lg-h5 {
        flex: 0 0 45%;
        max-width: 45%;
    }
}

@include media-breakpoint-up(xl) {
    .container.no-lg {
        max-width: 1210px;
    }
}

@include media-breakpoint-down(md) {
    &.with-pad {
        padding-top: $headerHeightCollapsed + 20px !important;
    }
}

@include media-breakpoint-up(lg) {
    &.with-pad {
        padding-top: $headerHeight + 40px !important;
    }
}

nav.pagination-wrapper {
    margin-top: 30px;
    overflow: hidden;

    .pagination {
        margin-bottom: 0;
        justify-content: center;

        .page-item {
            margin: 0 6px;

            .page-link {
                border-radius: 0.5rem;
                color: $dark;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                padding: 0;
                @include transition(all 120ms linear);
            }

            &.active .page-link {
                background-color: $primary;
                color: #fff;
            }
        }
    }
}

.sidebar {
    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        padding: 1.25rem;
        background: #fff;
        border-radius: 0 !important;
        z-index: 1050;
        width: 80%;
        overflow: scroll;
        direction: rtl;
        transform: translate3d(-100%, 0, 0);
        opacity: 0.5;
        @include transition(transform 0.3s ease, opacity 0.15s linear);
        will-change: transform, opacity;

        &.active {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        > * {
            direction: ltr;
        }

        .card-body {
            padding: 0 !important;
        }
    }
}

nav {
    overflow: auto;

    .nav {
        white-space: nowrap;
        flex-wrap: nowrap;
        min-width: min-content;
    }

    .nav-tabs .nav-link {
        color: $secondary;
        border: unset;
        text-align: center;
        padding: 1.25rem 1rem;
        font-weight: 600;
        opacity: 0.7;
        @include transition(opacity 120ms linear);

        @include media-breakpoint-up(md) {
            min-width: 150px;
        }

        &:hover {
            border: unset;
            opacity: 0.9;
        }
    }

    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        color: $primary;
        position: relative;
        opacity: 1;
        box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 4px;
            background-color: $primary;
            border-radius: 13px 13px 0 0;
        }
    }
}

section {
    .sec-header {
        margin-bottom: 60px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;
        }

        .sec-title {
            font-weight: 700;
            margin-bottom: 14px;
        }

        .sec-desc {
            color: $secondary;
            font-size: 1.1rem;
            margin-bottom: 0;
        }
    }

    &.with-pad {
        padding: 60px 0;
    }
}

label {
    font-weight: 600;
    font-size: 0.9rem;
}

.input-group {
    .form-control {
        padding-right: 0;
        height: 42px;
        border-right: 0;

        &:focus {
            box-shadow: unset;
            border-color: #ced4da;
        }
    }

    .input-group-prepend {
        margin-right: 0;

        .input-group-text {
            background: unset;
            border-right: 0;
            color: $dark;

            i {
                font-size: 22px;
            }
        }

        + .form-control {
            padding-right: 0.75rem;
            padding-left: 0;
            height: 42px;
            border-left: 0;
            border-right: 1px solid #ced4da;

            &:focus {
                box-shadow: unset;
                border-color: #ced4da;
            }
        }
    }

    .input-group-append {
        margin-left: 0;

        .input-group-text {
            background: unset;
            border-left: 0;
            color: $dark;

            i {
                font-size: 22px;
            }
        }
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: lightgray;
    opacity: 1;
}

.sector.sector-sea-green {
    background-color: $sea-green;
}

.sector.sector-indigo {
    background-color: $indigo;
}

.progress-wrapper {
    .step-num {
        position: absolute;
        z-index: 1;
        font-size: 15pt;
        padding: 3px 10px;
        font-weight: bold;
        color: $primary;
    }

    .progress-dot {
        min-height: 100%;
        margin: 0 24px 0 0;
        position: relative;

        &::after {
            content: "";
            display: block;
            min-width: 32px;
            min-height: 32px;
            background-color: #ffe7d9;
            // border: 3px solid lighten($primary, $amount: 20%);
            border-radius: 50px;
            position: relative;
            margin-top: 2px;
        }

        &::before {
            content: "";
            position: absolute;
            width: 1px;
            top: 4px;
            // background-color: rgba($secondary, 0.5);
            background-color: #cacccf;
            left: 0;
            right: 0;
            margin: auto;
            bottom: -4px;
        }
    }

    &:last-child {
        .progress-dot::before {
            display: none;
        }
    }

    .progress-title {
        font-weight: 600;
        font-size: 1.1rem;
        margin-bottom: 0.5rem;
    }

    .progress-desc {
        color: $secondary;
        margin-bottom: 2.8rem;
    }
}

@media (max-width: 480px) {
    .img-email {
        width: 100%;
    }

    .title-email {
        text-align: left;
        font-size: 16px;
    }

    .text-email {
        text-align: left;

        font-size: 14px;
    }
}

a:hover {
    text-decoration: unset;
}

.bg-grey {
    background-color: #f2f2f2;
}

.bg-gray {
    background-color: #fafafa;
}

.btn {
    font-weight: 600;
    border-width: 2px;
    border-radius: $border-radius;
}

.btn-lg {
    font-size: 1.1rem;
}

.text-greenlight {
    color: rgb(23, 255, 54) !important;
}

.text-green {
    color: rgb(3, 202, 30) !important;
}

.text-darkgreen {
    color: #43936c;
}
.text-orange {
    color: $primary !important;
}

.text-red {
    color: rgb(255, 59, 48) !important;
}

.text-reddark {
    color: rgb(186, 10, 0) !important;
}

.font-weight-medium {
    font-weight: 500;
}

.font-weight-semi-bold {
    font-weight: 600;
}

$font-sizes: (
    "0": 1rem,
    "1": 1.1rem,
    "2": 1.2rem,
    "3": 1.3rem,
    "4": 1.4rem,
    "n1": 0.9rem,
    "n2": 0.8rem,
    "n3": 0.7rem
);

@each $name, $size in $font-sizes {
    .fs-#{$name} {
        font-size: $size;
    }
}

.loading-dots {
    position: relative;
    // background-color: beige;
    margin: 20%;
    left: 30%;
    display: inline-flex;

    @media only screen and (max-width: 480px) {
        left: 10%;
        margin: 80% 30%;
    }
}

.password-eye {
    border-bottom-right-radius: 7px !important;
    border-top-right-radius: 7px !important;
    border: 1px solid #ccc !important;
    border-left: none !important;
}

.password-form {
    border-right: none !important;
}

.custom-file {
    height: 42px;

    .custom-file-input {
        height: 42px;
    }

    .custom-file-label {
        height: 42px;
        padding: 0.65rem 0.75rem;

        &::after {
            // content: "Upload";
            top: 0px;
            right: 0px;
            bottom: 6px;
            height: unset;
            // padding: 0.275rem 1.75rem;
            // background-color: $primary;
            background-color: white;
            border-left: unset;
            border-radius: 0.5rem;
            color: #868686;
            font-size: 20px;
            font-family: "Material Icons";
            content: "\f09b";
        }
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        font-family: "Material Icons";
        content: "\f09b";
    }
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
    background-color: rgba($secondary, 0.1);
}

.custom-control-label {
    line-height: 22px;
}

.custom-select {
    background: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 1.5 4 5'><path fill='%23FF7A00' d='M2 5L0 3h4z'/></svg>")
        no-repeat right 0.75rem center/8px 10px;
    height: 42px;
}

.foto-ktp {
    border-radius: 10px;
    width: 75%;
}

.foto-selfie {
    border-radius: 10px;
    width: 34%;

    @media (max-width: 480px) {
        width: 55%;
        margin-top: 20px;
    }
}

/* Rules for sizing the Material icon. */
[class*="material-icons"].md-18 {
    font-size: 18px;
}

[class*="material-icons"].md-24 {
    font-size: 24px;
}

[class*="material-icons"].md-36 {
    font-size: 36px;
}

[class*="material-icons"].md-48 {
    font-size: 48px;
}

/* Rules for using icons as black on a light background. */
[class*="material-icons"].md-dark {
    color: rgba(0, 0, 0, 0.54);
}

[class*="material-icons"].md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
[class*="material-icons"].md-light {
    color: rgba(255, 255, 255, 1);
}

[class*="material-icons"].md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
}

.swal2-icon {
    transform: scale(0.75);
}

.alert-box-warning {
    padding: 0.75rem 1.25rem;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    display: flex;
    // align-items: center;
    font-weight: 400;
    color: #8c4112;
    background: #fff5f0;
    font-size: 0.7rem;

    .icon {
        padding-right: 10px;
        padding-top: 2px;
        font-size: 12pt;
    }

    a {
        color: #8c4112;
        font-weight: 700;
    }

    .btn {
        font-size: 0.9rem;
    }
}

// .saldo {
//   margin-left: 45px !important;
//   opacity: 0.5;
// }
.saldo {
    margin-left: 45px !important;
    opacity: 0.5;
}

.saldo-hover {
    display: none;
    position: absolute;
    background-color: #ffffff;
    padding: 13px;
    z-index: 2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    opacity: 0;
}

.saldo:hover ~ .saldo-hover {
    display: flex;
    position: absolute;
    width: 100%;
    background-color: #ffffff;
    padding: 31px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    opacity: 1;
}

.icon-center,
.icon-center::before {
    width: 100%;
    text-align: center;
    font-size: 18px;
}

.gap-1 {
    gap: 8px;
}

.gap-2 {
    gap: 16px;
}

.gap-3 {
    gap: 32px;
}

.gap-half {
    gap: 4px;
}

.flex-1 {
    flex: 1;
}

.title-sign-card {
    min-height: 40px;
}

.hint-text {
    font-weight: 200;
    font-size: 0.75rem;
}

.modal-size {
    @media (max-width: 600px) {
        // padding-left: 6px;
        // width: 25px;
        // height: 28px;
        // font-size: 16pt;
        max-width: 100vw;
    }
    max-width: 60vw;
}

$spacing-scale: (
    0: 0,
    1: 4px,
    2: 8px,
    3: 12px,
    4: 16px,
    5: 24px,
    6: 32px,
    7: 40px
);

// Create margin utilities dynamically
@each $key, $value in $spacing-scale {
    // For margin-top (mt-*)
    .mtc-#{$key} {
        margin-top: $value;
    }

    // For margin-bottom (mb-*)
    .mbc-#{$key} {
        margin-bottom: $value;
    }

    // For margin-left (ml-*)
    .mlc-#{$key} {
        margin-left: $value;
    }

    // For margin-right (mr-*)
    .mrc-#{$key} {
        margin-right: $value;
    }

    // For margin (m-*)
    .mc-#{$key} {
        margin: $value;
    }

    // For margin on the x-axis (mx-*)
    .mxc-#{$key} {
        margin-left: $value;
        margin-right: $value;
    }

    // For margin on the y-axis (my-*)
    .myc-#{$key} {
        margin-top: $value;
        margin-bottom: $value;
    }
}
.dark-style {
    color: white;
    background-color: #2c2c2e;
}

.custom-date-input {
    // background: #F5F5F5;
    border-radius: 10px;
    cursor: pointer;
    color: transparent !important;
    caret-color: transparent;
    // padding-left: 32px;
    text-align: center;
    border: 1px solid var(--Neutral-40, #e0e0e0);
    user-select: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.react-datepicker {
    border-radius: 8px;
    border: 1px solid var(--Neutral-40, #e0e0e0);

    font-family: Arial, sans-serif;
    border: none;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    display: none;
}
.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day {
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    font-family: "Roboto", sans-serif;

    margin: 0.2rem;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
    background-color: white;
    color: black;
}
.react-datepicker__day:hover {
    background-color: #f0f0f0;
    color: #333;
}

.react-datepicker__day--selected {
    background-color: #ff7a00;
    color: white;
    border-radius: 50%;
}

.react-datepicker__header {
    background-color: white;
    color: white;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    margin-bottom: 1rem;
}

.react-datepicker__month-select {
    border: none;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}
.react-datepicker__year-select {
    border: none;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
}

.react-datepicker__close-icon {
    padding-right: 10px;
}
.react-datepicker__close-icon::after {
    background: #ff7a00;
}

.react-datepicker-popper {
    left: -30px !important;
}
