.section-dana-proteksi{
    padding: 75px 0;
    p{
        font-size: 1.1rem;
        color: #8E8E93;
        line-height: 1.6;
    }
}

.page-header {
    width: 100%;
    // min-height: 600px;
    background-position: center center;
    background-size: cover;
    padding: 40px 0px;
}

.paragraph{
    padding:0;
    margin-right: 500px;
    @media only screen and (max-width : 480px) {
        margin-right: 0;
    }
}
.box-header{
    margin: 30px 0;
    padding: 130px 80px;
    color: white;
    background-color: rgba(0, 0, 0, 0.65);
    @media only screen and (max-width : 480px) {
        background-color: rgba(0, 0, 0, 0.75);
        margin: 60px 0;
        padding: 35px 30px;
    }
}
.padding{
    margin-top: 70px;
}
.text-title{
    font-weight: 700;
    padding: 25px 0;
}
.padding-kerugian{
    padding: 60px 0;
}
.title-kerugian{
    font-weight: 700;
}
.title-rumus{
    font-weight: 600;
}
.rumus{
    padding: 20px 0
}
.ketentuan{
    font-size: 15px;
    font-style: italic;
}
.img-dana{
    width:100%;
    @media only screen and (max-width : 480px) {
        width: 70%;
    }
}
.img-grade{
    width:75%;
    @media only screen and (max-width : 480px) {
        width: 100%;
    }
}