@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#mengapa-kami-ada {
  padding: 6em 0;

  @media only screen and (max-width: 480px) {
    padding: 4em 0;
  }

  .text-title {
    font-weight: 700;
    line-height: 1.4;
    max-width: 650px;
    margin-bottom: 20px;
  }

  .text-intro {
    font-size: 1.1rem;
    color: $secondary;
    max-width: 970px;
    line-height: 1.6;
  }

  .reason-card .card-body {
    display: flex;
    padding: 1.5rem;

    @include media-breakpoint-up(xl) {
      padding: 3rem;
    }

    .card-title {
      font-weight: 700;
      margin-bottom: 0;
      letter-spacing: -1px;
      max-width: 418px;
      line-height: 1.3;
    }

    .double-quotes {
      width: 40px;
      margin-left: auto;
    }
  }
}
