.info {
  font-size: 13px;
  color: #52575c;
}

.click-detail {
  cursor: pointer;
}

.no-polis {
  @media only screen and (max-width: 480px) {
    width: 93px;
  }
}

.nama-pinjaman {
  font-size: 1.75rem;
  @media only screen and (max-width: 480px) {
    font-size: 1.45rem;
  }
}
.tipe-pinjaman {
  font-size: 1.25rem;
  @media only screen and (max-width: 480px) {
    font-size: 1rem;
  }
}

.gambar-proyek-porto {
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  height: 400px;
  border-radius: 8px;
  margin-bottom: 24px;
  @media only screen and (max-width: 480px) {
    height: 250px;
  }
}
