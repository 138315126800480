@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../sass/variables";
@import "../../sass/mixins";

.dot-email {
  width: 32px;
  background-color: #ffe7d9;
  border-radius: 50%;
  display: block;
  height: 32px;
  padding: 1px;
  margin-top: 2px;
  span {
    font-size: 14pt;
    padding: 3px 10px;
    font-weight: bold;
    color: $primary;
  }
}

.input-otp {
  // padding-left: 12px;
  // padding-bottom: 10px;
  margin-right: 1rem;
  padding: 0 11px 0 14px;
  width: 45px;
  height: 45px;
  font-size: 25px;
  line-height: 150%;
  // font-weight: bold;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 8px;

  @media (max-width: 480px) {
    // padding-left: 6px;
    // width: 25px;
    // height: 28px;
    // font-size: 16pt;
    width: 35px;
    height: 35px;
    font-size: 16pt;
    margin-right: 0.5rem;
    padding: 0 10px 0 10px;
  }
}

.input-otp:last-of-type {
  margin-right: 0;
}

.input-otp:focus {
  outline: none;
  border: 1px solid $primary;
  box-sizing: border-box;
  border-radius: 8px;
  // border-bottom: 5px solid $primary;
}

.icon-mail {
  width: 30%;
  @media (max-width: 480px) {
    width: 50%;
  }
}
