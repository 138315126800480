@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

a.article-card {
    border: unset;
  
    .card-body {
      padding: .75rem;
  
      .poster {
        display: flex;
        align-items: center;
        color: $secondary;
        margin-bottom: 12px;
        font-size: .9rem;
        
        p {
          margin-bottom: 0;
        }
  
        span.dot {
          font-size: 1rem;
          margin: {
            left: 6px;
            right: 6px;
          }
        }
      }
  
      .card-title {
        font-size: 1.1rem;
        font-weight: 600;
        color: $dark;
        margin-bottom: 6px;
        @include transition(color 120ms linear);
  
        height: 55px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
  
      .card-text {
        color: $secondary;
        height: 88px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }
  
    &:hover .card-title {
      color: $primary;
    }
  }