.card-item {
    border-radius: "8px";
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    cursor: "pointer";
    color:black;
}
.card-item:hover {
    box-shadow: 0px 4px 12px 0px rgba(255, 122, 0, 0.4);
    transform: scale(1.03);
}
