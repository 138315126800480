section#tiketing {

    h3 {
        font-weight: 700;
        text-align: center;
    }

    .text-form {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #8E8E93;
    }

    .border-container {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        margin: 40px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 40px 0;
        flex-direction: column;
        align-items: center;
    }

    .container-form {
        max-width: 966px;
    }

    .container-tiketing {
        width: 70%;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        flex: none;
        order: 1;
        flex-grow: 0;
        padding: 24px 32px;

        @media only screen and (max-width : 480px) {
            width: 100%;
            padding: 16px;

        }
    }

    .label-select {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        opacity: 0.6;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 42px;
    }

    textarea {
        min-height: 78px;
    }
}