@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#testimoni {
  margin-top: 40px;

  .r-16 {
    border-radius: 16px;
  }

  .height-mobile {
    @media only screen and (max-width: 480px) {
      height: 366px;
    }
  }

  .owl-carousel,
  .owl-stage-outer,
  .owl-stage,
  .owl-carousel .owl-item,
  [class^="item"] {
    height: 370px;

    @media only screen and (max-width: 480px) {
      height: 100%;
    }
  }

  .owl-nav {
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }

  .web-testi {
    position: relative;

    .owl-prev,
    .owl-next {
      position: absolute;
      top: 25%;
      transform: translateY(-50%);
      font-size: 23px;
      color: $primary;
      position: absolute;
      border: 3px solid;
      // padding-left: 5px;
      border-radius: 70%;
      width: 31.8px;
      height: 32px;
      margin: 2rem;
      background-color: $primary;

      @media only screen and (max-width: 480px) {
        position: absolute;
        top: auto;
        transform: inherit;
        margin-top: 2px;
        margin: 8px;
        width: 28.8px;
        height: 29px;
      }

      @media only screen and (min-width: 540px) and (max-width: 540px) {
        display: none;
      }
    }

    .owl-prev {
      @media only screen and (max-width: 480px) {
        left: 5rem;
      }
    }

    .owl-next {
      right: 20px;

      @media only screen and (max-width: 480px) {
        right: 5rem;
      }
    }

    .icons {
      font-size: 15px;
      color: white;
      top: -6px;
      position: relative;
      padding: 7px;

      @media only screen and (max-width: 480px) {
        font-size: 15px;
        padding: 6px;
        top: -7px;
      }
    }

    .active {
      left: -325px;
      z-index: -1;
    }

    .owl-item {
      right: -305px;
      top: 0;
      transform: scale(0.805);
      transition: transform 0.3s;

      .card-body {
        height: 220px;

        @media only screen and (max-width: 480px) {
          opacity: 1;
        }

        opacity: 0.6;
      }
    }

    .owl-item .active,
    .center {
      left: 0;
      z-index: 0;
      top: 0;
      transform: scale(1);
      transition: transform 0.3s;

      .card-body {
        background-color: white !important;
        margin: 20px;
        opacity: 1;
      }
    }

    .owl-theme .owl-dots {
      top: 40%;
      margin-top: -210px;
      position: relative;
      text-align: left;
      display: flex;
      padding: 10px 8.4rem;
      justify-content: center;

      .owl-dot span {
        border-radius: 20px;
        display: block;
        height: 12px;
        margin: 5px 7px;
        opacity: 0.5;
        width: 12px;
        background: #ff7a00 0% 0% no-repeat padding-box;
        opacity: 0.25;
      }

      .owl-dot.active span {
        border-radius: 20px;
        display: block;
        height: 12px;
        margin: 5px 7px;
        opacity: 0.5;
        width: 12px;
        background: #ff7a00 0% 0% no-repeat padding-box;
        opacity: 1;
      }
    }
  }

  .mobile-testi {
    .owl-item {
      left: -18px;
    }

    .active {
      left: 0;
    }

    .owl-theme .owl-dots {
      // top: 50%;
      // margin-top: -210px;
      margin-top: 15px;
      position: relative;
      text-align: left;
      display: flex;
      padding: 10px 8.4rem;
      justify-content: center;

      .owl-dot span {
        border-radius: 20px;
        display: block;
        height: 12px;
        margin: 5px 7px;
        opacity: 0.5;
        width: 12px;
        background: #ff7a00 0% 0% no-repeat padding-box;
        opacity: 0.25;
      }

      .owl-dot.active span {
        border-radius: 20px;
        display: block;
        height: 12px;
        margin: 5px 7px;
        opacity: 0.5;
        width: 12px;
        background: #ff7a00 0% 0% no-repeat padding-box;
        opacity: 1;
      }
    }
  }

  .title {
    font-weight: 700;
    padding-top: 40px;
    padding-bottom: 8px;
  }

  .body-testi {
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  section#testimoni {
    .owl-item {
      right: -515px;
    }

    .name-lender {
      font-size: 12pt;
    }

    .occupation {
      font-size: 9pt;
    }

    .body-testi {
      font-size: 8.9pt;
      padding-top: 20px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  section#testimoni {
    .owl-item {
      right: -688px;
    }

    .body-testi {
      font-size: 10.4pt;
      padding-top: 20px;
    }
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  section#testimoni {
    .owl-item {
      right: -688px;
    }

    .body-testi {
      font-size: 10.4pt;
      padding-top: 20px;
    }

    .name-lender {
      font-size: 15pt;
    }

    .occupation {
      font-size: 12pt;
    }
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  section#testimoni {
    .owl-item {
      right: -305px;
    }

    .body-testi {
      padding-top: 20px;
    }
  }
}