@import "../../../sass/styles.scss";

header {
    // This variable is used to push down the section below header so it's do not stack
    // **CHANGE BELOW VALUE IF HEADER HEIGHT CHANGED **
    $headerHeight: 108.38px;
    $headerHeightExpanded: 128.38px;
    $headerHeightCollapsed: 71.38px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
  
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 1;
      z-index: -1;
      background: linear-gradient(to bottom, black 70%, transparent 0);
      @include transition(opacity 120ms ease);
    }
  
    section#company-info {
      padding: 10px 36px;
      font-size: 0.9rem;
      line-height: 1;
      display: none;
      @include transition(padding 120ms ease);
  
      @include media-breakpoint-up(lg) {
        display: block;
        // margin: {
        //   left: -30px;
        //   right: -30px;
        // }
      }
  
      .info-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
  
        a {
          color: #fff;
          @include transition(color 120ms linear);
    
          &:hover {
            color: darken(#fff, 15%);
          }
        }
    
        .item {
          display: inline-flex;
          align-items: center;
          margin: 0 15px;
    
          p {
            font-weight: 600;
          }
    
          &.social-icons {
            margin-right: 0;
            a {
              margin: 0 10px;
  
              &:first-child {
                margin-left: 0;
              }
  
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  
    nav {
      overflow: unset;
    }
  
    nav.navbar {
      box-shadow: 0 0 10px rgba(152, 152, 152, 0.16);
      font-weight: 600;
  
      @include media-breakpoint-up(lg) {
        // margin: {
        //   left: -30px;
        //   right: -30px;
        // }
        border-radius: 13px;
      }
  
      .container {
        justify-content: unset;
  
        @include media-breakpoint-up(lg) {
          align-items: unset;
        }
  
        #sidebarToggler {
          display: flex;
          margin-right: 14px;
  
          @include media-breakpoint-up(lg) {
            display: none;
          }
        }
  
        .navbar-toggler {
          margin-left: auto;
        }
  
        .navbar-brand {
          margin-right: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media-breakpoint-up(xl) {
            margin-right: 50px;
          }
        }
  
        .navbar-collapse {
          align-items: unset;
  
          .navbar-nav .nav-item {
            display: flex;
            position: relative;
  
            .dropdown{
              @media only screen and (max-width : 480px) {
                display: inline !important;
              }
            }
            
            .nav-link {
              display: flex;
              align-items: center;
              flex: 1;
              // font-weight: 600;
              color: $dark;
              @include transition(color 120ms linear);
            }
  
            .nav-link:hover,
            &.active .nav-link {
              color: $primary;
            }
  
            @include media-breakpoint-up(lg) {
              &::after {
                content: ' ';
                display: block;
                height: 4px;
                border-radius: 2px 2px 0 0;
                position: absolute;
                bottom: -.5rem;
                left: .75rem;
                right: .75rem;
                background: $primary;
                transform: scaleX(0);
                transform-origin: center;
                @include transition(transform 250ms ease);
              }
        
              &:hover::after {
                transform: scaleX(1);
              }
        
              &.active::after {
                transform: scaleX(1);
              }
            }
          }
  
          .dropdown-menu{
            @media only screen and (max-width : 480px) {
              background-color: #f5f5f5;
              width: 100%;
              border: 0;
            }
          }
          
          //timpa dari reactstrap
          .btn-secondary{
            background-color: white;
            border-color: white;
          }

          .btn-secondary.dropdown-toggle:focus {
            display: none;
          }
          //

          .nav-right {
            display: flex;
            align-items: center;
            margin-left: auto;
            padding: 0.5rem 1rem;
            a.nav-link {
              @include transition(color 120ms linear);
  
              // &:hover {
              //   color: darken($primary, 3%);
              // }
            }
            @media only screen and (max-width : 480px) {
            margin-left: -20px;
            }
  
            @include media-breakpoint-up(xl) {
              a.btn {
                padding: {
                  left: 2rem;
                  right: 2rem;
                }
                margin: {
                  left: 1rem;
                  right: 1rem;
                }
              }
            }
  
            .tkb {
              margin-left: 15px;
              display: flex;
              width: 100%;
              max-width: 112px;
              align-items: center;
  
              @media only screen and (max-width : 480px) {
                margin-left:70px;
              }
            }
          }
        }
      }
  
      .navbar-nav .nav-link {
        @include media-breakpoint-up(xl) {
          padding-right: 0.75rem;
          padding-left: 0.75rem;
        }
      }
  
      .tkb-toggler {
        user-select: none;
      }
  
      .tkb-popover {
        position: absolute;
        right: 0 !important;
        left: unset !important;
        top: 100% !important;
        max-width: 380px;
        background: #fff;
        border-radius: .5rem;
        font-weight: 400;
        margin-top: 20px !important;
        transform: unset !important;
  
        @include media-breakpoint-down(lg) {
          margin-left: 20px;
          margin-right: 20px;
          left: 0 !important;
        }
  
        .popover-body {
          padding: 18px;
        }
      }
    }
  
    .container {
      @include media-breakpoint-down(md) {
        max-width: unset;
        padding: 0;
      }
    }
  
    &.transparent {
      &::before {
        opacity: 0;
      }
  
      section#company-info {
        padding: 20px 36px;
      }
    }
    @include media-breakpoint-up(lg) {
      &.transparent+*:not(#home-carousel) {
        padding-top: $headerHeightExpanded !important;
      }
    
      +*:not(#home-carousel) {
        padding-top: $headerHeight !important;
  
        &.with-pad {
          padding-top: $headerHeight + 40px !important;
        }
      }
    
    }
  }
  
  header nav.navbar .container .navbar-collapse .navbar-nav .nav-item {
    @media only screen and (max-width: 480px){
      display: inline !important;
    }
  }
.tkb-right{
  display: none;
  width: 100%;
  overflow: hidden;
  max-width: 112px;
  @media only screen and (max-width : 480px) {
    display: flex;
   
    margin-left: auto!important;
  }
  @include media-breakpoint-down(md) {
    display: flex;
    margin-left: auto!important;
  }
}





