@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#potensi {
  padding: 6em 0;

  @media only screen and (max-width: 480px) {
    padding: 4em 0;
  }

  .potensi-title {
    font-weight: 700;
    line-height: 1.4;
    max-width: 650px;
    margin-bottom: 30px;
    @media only screen and (max-width: 480px) {
      margin-top: 20px;
    }
  }

  .potensi-intro {
    font-size: 1.1rem;
    color: $secondary;
    max-width: 970px;
    line-height: 1.6;
  }

  .potensi-img-wrapper {
    position: relative;
    margin-top: 80px;
  }

  @include media-breakpoint-up(xs) {
    .potensi-img-wrapper {
      height: 1000px;

      img {
        max-width: 100%;
        height: auto;
        position: absolute;

        &:nth-child(1) {
          width: 400px;
          left: 0;
          top: 0;
          z-index: -1;
        }

        &:nth-child(2) {
          width: 230px;
          right: 3%;
          top: 39%;
        }

        &:nth-child(3) {
          width: 180px;
          left: 3%;
          top: 25%;
        }

        &:nth-child(4) {
          width: 280px;
          left: 0%;
          bottom: 25%;
        }

        &:nth-child(5) {
          width: 170px;
          right: 4%;
          top: 16%;
        }

        &:nth-child(6) {
          width: 320px;
          right: 0;
          bottom: 0;
          z-index: -1;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .potensi-img-wrapper {
      height: 700px;

      img {
        max-width: 100%;
        height: auto;
        position: absolute;

        &:nth-child(1) {
          width: 400px;
          left: 0;
          top: 0;
          z-index: -1;
        }

        &:nth-child(2) {
          width: 230px;
          left: 15%;
          top: 40%;
          right: unset;
          bottom: unset;
        }

        &:nth-child(3) {
          width: 180px;
          right: 17%;
          top: 31%;
          left: unset;
          bottom: unset;
        }

        &:nth-child(4) {
          width: 280px;
          left: 6%;
          bottom: 3%;
          right: unset;
          top: unset;
        }

        &:nth-child(5) {
          width: 170px;
          right: 8%;
          top: 3%;
          left: unset;
          bottom: unset;
        }

        &:nth-child(6) {
          width: 320px;
          right: 0;
          bottom: 0;
          z-index: -1;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .potensi-img-wrapper {
      height: 500px;

      img {
        max-width: 100%;
        height: auto;
        position: absolute;

        &:nth-child(1) {
          width: 400px;
          left: 0;
          top: 0;
          z-index: -1;
        }

        &:nth-child(2) {
          width: 230px;
          left: 15%;
          top: 52%;
          right: unset;
          bottom: unset;
        }

        &:nth-child(3) {
          width: 180px;
          left: 37%;
          top: 9%;
          right: unset;
          bottom: unset;
        }

        &:nth-child(4) {
          width: 280px;
          left: 46%;
          top: 51%;
          right: unset;
          bottom: unset;
        }

        &:nth-child(5) {
          width: 170px;
          right: 19%;
          top: 2%;
          left: unset;
          bottom: unset;
        }

        &:nth-child(6) {
          width: 320px;
          right: 0;
          top: 26%;
          bottom: unset;
          z-index: -1;
        }
      }
    }
  }
}
