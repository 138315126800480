
section#registered-in {
    .ojk {
      width: 230px
    }
  
    .afpi {
      width: 260px;
      height: 92px;
      object-fit: contain;
      object-position: center;
    }
  }