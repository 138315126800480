.container-border {
  border: 1px solid #c2c2c2;
  border-radius: 8px;
  padding: 10px 20px;
}

.container-bg {
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 10px 20px;
}

.container-border-pm {
  border: 1px solid #ff7a00;
  border-radius: 8px;
  padding: 10px 20px;
}

.f-14 {
  font-size: 14px;
}

.f-18 {
  font-size: 18px;
}

// .bullet-radio {
//   position: absolute;
//   display: block;
//   width: 1rem;
//   height: 1rem;
//   top: 0.5rem;
// }
.bullet-radio.disabled:hover {
box-shadow: 0 0 5px 0px white inset !important;
}
.custom-radio-bank {
  input[type="radio"] {
    -webkit-appearance: none;
    width: 1rem;
    height: 1rem;
    top: 0.5rem;
    border: 1px solid darkgray;
    border-radius: 50%;
    // outline: none;
    // box-shadow: 0 0 5px 0px gray inset;
  }

  input[type="radio"]:hover {
    box-shadow: 0 0 5px 0px orange inset;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 80%;
    height: 80%;
    margin: 10% auto;
    border-radius: 50%;
  }
  input[type="radio"]:checked:before {
    background: #ff7a00;
    border: 1px solid #ff7a00;
  }
}
