@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

.modal-content {
  border-radius: $border-radius !important;
}

.modal-body {
  padding: 1.75rem;
}

.modal-header {
  padding: 2rem 1rem 1rem 1rem !important;
  border: unset !important;

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 2rem 2rem !important;
    @include transition(opacity 120ms linear);
  }

  .close:focus {
    outline: none;
  }

  span {
    font-size: xx-large;
  }

  .modal-title {
    margin-bottom: 6px;
    width: 100%;
  }

  +.modal-body {
    padding: 0.75rem 1.75rem 1.75rem 1.75rem;
  }
}

.over-hidden {
  overflow: hidden !important;
}

.login-modal {
  .login-nav {
    .nav-tabs .nav-link {
      width: 50%;
    }
  }

  form {
    input::placeholder {
      color: rgba($dark, 0.5);
      @include transition(color 120ms linear);
    }

    input:focus::placeholder {
      color: rgba($dark, 0.75);
    }

    // Firefox < 19
    input:-moz-placeholder {
      color: rgba($dark, 0.5);
      @include transition(color 120ms linear);
    }

    input:focus:-moz-placeholder {
      color: rgba($dark, 0.75);
    }

    // Firefox > 19
    input::-moz-placeholder {
      color: rgba($dark, 0.5);
      @include transition(color 120ms linear);
    }

    input:focus::-moz-placeholder {
      color: rgba($dark, 0.75);
    }

    // Internet Explorer 10
    input:-ms-input-placeholder {
      color: rgba($dark, 0.5);
      @include transition(color 120ms linear);
    }

    input:focus:-ms-input-placeholder {
      color: rgba($dark, 0.75);
    }

    button[type="submit"] {
      margin-top: 40px;
    }
  }
}