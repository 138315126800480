@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#home-carousel {
  height: 700px;

  h1 {
    font-size: 2.1rem;
  }
  @media only screen and (max-width: 480px) {
    height: 860px;
  }
  @include media-breakpoint-up(xl) {
    height: 800px;

    h1 {
      font-size: 2.5rem;
    }
  }

  @media only screen and (min-width: 640px) and (max-width: 768px) {
    height: 500px;
  }

  .panah-orange {
    @media only screen and (min-width: 640px) {
      display: none;
    }
  }
  .owl-carousel,
  .owl-stage-outer,
  .owl-stage,
  .owl-carousel .owl-item,
  [class^="item"] {
    height: 100%;
  }

  .owl-theme .owl-dots {
    margin-top: -210px;
    position: relative;
    text-align: left;
    display: flex;
    padding: 10px 8.4rem;
    @media only screen and (min-width: 640px) and (max-width: 812px) {
      padding: 0px 4.4rem;
    }
    @include media-breakpoint-down(md) {
      margin-top: -125px;
      justify-content: center;
    }

    @include media-breakpoint-down(xs) {
      margin-top: -100px;
      display: none;
    }

    @include media-breakpoint-up(lg) {
      margin-top: -150px;
      justify-content: center;
    }

    @include media-breakpoint-up(xl) {
      margin-top: -200px;
      justify-content: center;
    }

    .owl-dot:first-child {
      margin-left: -7px;
    }

    .owl-dot span {
      border-radius: 20px;
      display: block;
      height: 12px;
      margin: 5px 7px;
      opacity: 0.5;
      width: 12px;
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 0.5;
    }

    .owl-dot.active span {
      border-radius: 20px;
      display: block;
      height: 12px;
      margin: 5px 7px;
      opacity: 0.5;
      width: 12px;
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }

  .item {
    display: flex;
    align-items: center;

    .bg-orange {
      content: "";
      position: absolute;
      top: 0;
      left: -1px;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 65%;
      height: auto;
      // width: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url("https://frontend-picture.s3-ap-southeast-1.amazonaws.com/bg-12.png");
      background-position: -8rem;

      @media only screen and (max-width: 480px) {
        background-image: url("/images/home-carousel/bg-1.png");
        width: 101%;
        height: 500px;
        background-position: 0;
      }
      @media only screen and (min-width: 640px) and (max-width: 812px) {
        width: 70%;
        left: 1px;
      }
      @media only screen and (min-width: 1023px) and (max-width: 1024px) {
        width: 77%;
        background-position: -13rem;
        left: 1px;
      }
    }

    .bg-img {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
      // background-position: 40px;
      background-position: right;

      @media only screen and (max-width: 480px) {
        // background-position: right 415px;
        background-position: right 480px;
        background-size: 130%;
      }
      @media only screen and (min-width: 640px) and (max-width: 812px) {
        background-size: 600px;
        background-position: 255px;
      }
      @media only screen and (min-width: 1023px) and (max-width: 1024px) {
        background-position: 330px top;
        background-size: 800px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      // background-color: rgba(0, 0, 0, 0.5);
    }
  }

  h1 {
    max-width: 900px;
    margin-bottom: 1rem;
    font-size: 39px;
    @media only screen and (max-width: 480px) {
      font-size: 29px;
    }
    @media only screen and (min-width: 1023px) and (max-width: 1024px) {
      font-size: 35px;
    }
  }

  .common-text {
    color: #fff;
    max-width: 630px;
    line-height: 32.5px;
    margin-bottom: 32px;
    z-index: 2;
    @media only screen and (max-width: 480px) {
      font-size: 13pt;
    }
    @media only screen and (min-width: 640px) and (max-width: 812px) {
      font-size: 0.8rem;
      line-height: 20px;
    }
    @media only screen and (min-width: 1023px) and (max-width: 1024px) {
      font-size: 1.2rem;
    }
  }

  .container-banner {
    padding: 15rem 8.4rem;
    width: 941px;
    position: relative;
    z-index: 2;
    @media only screen and (max-width: 480px) {
      padding: 5rem 1.4rem;
      width: auto;
    }
    @media only screen and (min-width: 766px) and (max-width: 768px) {
      width: 470px;
    }
    @media only screen and (min-width: 640px) and (max-width: 812px) {
      padding: 8.3rem 3.8rem;
      width: 460px;

      h1 {
        font-size: 1.4rem;
        line-height: 36px;
      }
    }
    @media only screen and (min-width: 1023px) and (max-width: 1024px) {
      padding: 13rem 5.4rem;
      width: 700px;
    }
  }
  .btn-white {
    background-color: #fff;
    color: $primary;
    font-weight: 700;
    padding: 8px 12px;
    border-radius: 8px;
    @media only screen and (min-width: 640px) and (max-width: 812px) {
      font-size: 8.7pt;
    }
  }

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #ffa569;
    position: absolute;
    border-radius: 70%;
    width: 31.8px;
    height: 32px;
    margin: 1rem;
    @media only screen and (max-width: 480px) {
      position: absolute;
      top: auto;
      transform: inherit;
      margin-top: 2px;
      margin: 8px;
      width: 28.8px;
      height: 29px;
    }
    @media only screen and (min-width: 540px) and (max-width: 540px) {
      display: none;
    }
  }

  .owl-prev {
    @media only screen and (max-width: 480px) {
      left: 5rem;
    }
  }

  .owl-next {
    right: 0;
    @media only screen and (max-width: 480px) {
      right: 5rem;
    }
  }

  .icons {
    font-size: 25px;
    color: white;
    top: -4px;
    position: relative;
    padding: 7px;
    @media only screen and (max-width: 480px) {
      font-size: 15px;
      padding: 6px;
      top: -7px;
    }
  }
}
