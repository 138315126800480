@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

section#our-partners {
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    margin: auto;
    @media only screen and (max-width: 480px) {
      max-width: 850px;
    }
  }

  .icon {
    width: 130px;
    max-height: 130px;
    margin: 20px 30px;
    display: flex;

    img {
      object-fit: contain;
      object-position: center;
    }

    &.sf {
      width: 130px;
      @media only screen and (max-width: 480px) {
        width: 80px;
      }
    }

    .rii {
      width: 110px;
    }

    &.elex {
      width: 80px;
    }

    &.naso {
      align-items: center;
      margin: 0 20px;
      width: 150px;
      height: 80px;

      img {
        object-fit: cover;
        object-position: center;
      }

      @media only screen and (max-width: 480px) {
        width: 130px;
      }
    }

    &.ryan {
      margin: 0 20px;
      width: 160px;
      height: 145px;

      img {
        object-fit: cover;
        object-position: center;
      }

      @media only screen and (max-width: 480px) {
        width: 120px;
      }
    }

    &.pigijo {
      width: 120px;
      @media only screen and (max-width: 480px) {
        width: 90px;
      }
    }

    &.dipay {
      width: 120px;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }
    }

    &.digisign {
      width: 150px;
      @media only screen and (max-width: 480px) {
        width: 110px;
      }
    }

    &.fineoz {
      width: 150px;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }
    }

    &.collpindo {
      width: 150px;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }
    }

    &.nicepay {
      width: 120px;
      @media only screen and (max-width: 480px) {
        width: 90px;
      }
    }

    &.jps {
      width: 200px;
      @media only screen and (max-width: 480px) {
        width: 90px;
      }
    }

    &.nicepay {
      width: 120px;
      @media only screen and (max-width: 480px) {
        width: 90px;
      }
    }

    &.mandiri {
      width: 140px;
      @media only screen and (max-width: 480px) {
        width: 95px;
      }
    }

    &.bca {
      width: 140px;
      @media only screen and (max-width: 480px) {
        width: 100px;
      }
    }
  }
}
