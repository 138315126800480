@import "../../../../sass/styles.scss";

footer {
  background-color: $second-dark;
  padding-top: 60px;
  color: #fff;

  .disclaimer-risiko {
    margin-bottom: 40px;
  }

  .kontak-kami {
    padding: 0 60px;
    @media only screen and (max-width: 480px) {
      padding: 0 15px;
    }
  }

  a {
    color: #acacac;
  }

  .icons {
    color: white;
  }
  a:hover {
    color: white;
    text-decoration: underline;
  }

  .text-second {
    color: #acacac;
  }
  .line-height-medium {
    line-height: 1.6;
  }
  ol.risk-list {
    padding-inline-start: 20px;
    margin-bottom: 0;

    li {
      padding-left: 8px;
      font-size: 0.9rem;
      line-height: 2;
    }
  }

  .border-divider {
    min-height: 100%;
    width: 1px;
    background: linear-gradient(
      to bottom,
      rgba($secondary, 0.1),
      rgba($secondary, 0.5),
      rgba($secondary, 0.1)
    );
    margin: 8px 0 0 12px;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  hr {
    border-color: rgba($secondary, 0.5);
    margin: 0;
  }

  .copyright {
    color: #d1d1d6;
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }

    .links {
      text-align: center;
      @include media-breakpoint-up(xl) {
        margin-left: auto;
      }

      a {
        color: #d1d1d6;
        margin: 12px 24px;
        display: block;
        @include transition(color 120ms linear);
        @include media-breakpoint-up(md) {
          display: inline-block;
          margin: 0 24px;
          width: 115px;
        }
        @media only screen and (max-width: 480px) {
          margin: 12px 20px;
        }

        &:hover {
          color: darken(#d1d1d6, 10%);
        }
      }
    }
  }
}
