@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#guaranteedby-ojk {
  padding: 6em 0;
  background-color: #fafafa;
  @media only screen and (max-width: 480px) {
    padding: 4em 0;
  }
  .info-text {
    font-weight: 600;
    line-height: 1.8;
  }

  .img-ojk {
    text-align: right;
    @media only screen and (max-width: 480px) {
      text-align: center;
    }
  }
}
