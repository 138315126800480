@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#akselerasi {
  padding: 0 0 80px;

  .text-secondary {
    @media only screen and (max-width: 480px) {
      margin-top: 30px;
    }
    .text {
      line-height: 1.9;
      font-weight: 600;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
  }
}
