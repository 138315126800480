@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#statistik {
  padding-bottom: 80px;
  padding-top: 60px;
  .text-title {
    font-weight: 700;
    line-height: 1.4;
    max-width: 650px;
  }

  .text {
    font-size: 1.1rem;
    color: #8e8e93;
    max-width: 970px;
    line-height: 1.6;
  }
  .icon-responsive {
    min-width: 80px;
    width: 100%;
    max-width: 20%;
    height:auto,
  }
  .sec-header {
    margin-bottom: 60px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }

    .sec-title {
      font-weight: 700;
      margin-bottom: 14px;
    }
  }
}
