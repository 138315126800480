@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";
// @import url("https://use.fontawesome.com/releases/v4.7.0/css/all.css");


.pick-daftar {
    .card-body {
      padding: 2rem;
  
      @include media-breakpoint-up(lg) {
        padding: 3.5rem;
      }
  
      .btn {
        width: 100%;
      }
  
      @include media-breakpoint-up(md) {
        .btn {
          width: auto;
          min-width: 270px;
        }
      }
    }
  }

.progressbar{
  display: inline-flex;
  padding-left: 0;

  #store:before{
    font-family: "Material Icons";
    content: "\e8d1";
  }

  #employee:before{
    font-family: "Material Icons";
    content: "\e8d3";
  }

  #account:before{
    font-family: "Material Icons";
    content: "\e853";
  }

  #work:before{
    font-family: "Material Icons";
    content: "\e8f9"
  }

  #contact:before{
    font-family: "Material Icons";
    content: "\e0ba"
  }

  #company:before{
    font-family: "Material Icons";
    content: "\e0af";
  }

  #check:before{
    font-family: "Material Icons";
    content: "\e5ca";
  }
  #address:before{
    font-family: "Material Icons";
    content: "\e55f";
  }
  #file:before{
    font-family: "Material Icons";
    content: "\e226";
  }
  #pic:before{
    font-family: "Material Icons";
    content: "\e851";
  }
  #addPic:before{
    font-family: "Material Icons";
    content: "\e56a";
  }
li{
  list-style-type: none;
    font-size: 15px;
    width: 30%;
    float: left;
    position: relative;
    font-weight: 400;
    text-align: center;
}
  li::before{
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 25px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px
  }

  
  li.active:before,
  li.active:after {
    background: $primary;
}
}
  .arrow-next{
    position: relative;
    top: 5px;
    padding-left: 5px;
  }
  .arrow-left{
    position: relative;
    top: 5px;
  }