@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

section#faq {
  .faq-header {
    background-color: $primary;
    border-radius: $border-radius;
    color: #fff;
    padding: 80px 60px;
    margin-bottom: 60px;

    h2 {
      font-weight: 700;
      margin-bottom: 10px;
    }

    h4 {
      font-weight: 400;
    }
  }

  .text {
    color: $secondary;
    margin-bottom: 8px;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    margin: -14px 0;
    position: sticky;
    top: 120px;
    margin-bottom: 60px;

    @include media-breakpoint-up(lg) {
      padding-left: 40px;
      margin-bottom: 0;
    }

    p {
      color: $dark;
      @include transition(color 120ms linear);
      padding: 16px 20px;
      cursor: pointer;

      &.active {
        color: $primary;
        font-weight: 600;
      }

      &:hover {
        color: $primary;
      }
    }

    a {
      color: $dark;
      @include transition(color 120ms linear);
      padding: 16px 20px;

      &.active {
        color: $primary;
        font-weight: 600;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  .questions {
    hr {
      margin: 2.25rem 0;
    }

    h3 {
      color: $primary;
      font-weight: 700;
      margin-bottom: 32px;
    }

    .question {
      h4 {
        color: $secondary;
        font-weight: 700;
        margin-bottom: 22px;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}