.loader {
    display: inline-block;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid orange;
    /* margin-top: 5px; */
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
   
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
   
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .modal-loader {
    background-color: black;
  }