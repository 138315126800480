@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

section#tanpa-biaya {
  padding: 60px 180px;

  .text-title {
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 30px;
    text-align: center;
  }

  .text {
    font-size: 1.1rem;
    color: #8E8E93;
    line-height: 1.6;
    text-align: center;
  }

  @media (max-width: 480px) {
    padding: 0px;
  }
}