@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

.modal-content {
    border-radius: $border-radius !important;
}

.modal-body {
    padding: 2.75rem;
}

.modal-header {
    padding: 2rem 1rem 1rem 1rem !important;
    border: unset !important;

    span {
        font-size: xx-large;
    }

    .modal-title {
        margin-bottom: 6px;
        width: 100%;
    }

    .modal-body {
        padding: 0.75rem 1.75rem 1.75rem 1.75rem;
    }
}

.container-pop-kebijakan {
    background: #F5F5F5;
    height: 285px;
    padding: 12px 8px;
    overflow-y: auto;
}

.text-title-privacy {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 18px;
}

.text-paragraf {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #404040;
    text-align: left;
}

.button-container-data {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;

    .btn-secondary {
        border: none;
        color: #ff7a00;
        background: #fffaf4;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
        width: 172px;

        .btn-secondary:hover {
            background: #fffaf4;
        }
    }
}