@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#definisi {
  padding-top: 60px;
  .sec-header {
    margin-bottom: 60px;
    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }

    .sec-title {
      font-weight: 700;
      margin-bottom: 14px;
    }
  }

  .header {
    width: 100%;
    height: auto;
    background-position: center center;
    background-size: cover;
    padding: 8rem 15rem;
    border-radius: 16px;
    @media only screen and (max-width: 480px) {
      padding: 2.2em 1.1em;
    }
  }

  .padding-section {
    padding: 6em 0;
    @media only screen and (max-width: 480px) {
      padding: 4em 0;
    }
  }

  .title-header {
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 22pt;
    @media only screen and (max-width: 480px) {
      font-size: 13pt;
    }
  }

  .img-svg {
    width: 60%;
    @media only screen and (max-width: 480px) {
      padding: 0.9em 0;
    }
  }

  .definisi {
    color: $secondary;
    line-height: 2;
    margin-bottom: 20px;
  }
}
