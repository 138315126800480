.modal__title {
    color: #262321;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
}

.modal__content {
    color: #262321;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    line-height: 150%; /* 21px */
}

.modal__content > p {
    margin: 0;
}
.modal__content-wrapper {
    gap: 24px;
}

.modal__content__value {
    font-weight: 600;
}
.modal__content__summary-desc {
    color: #ff7a00;
    font-weight: bold;
}
.modal__content__summary-value {
    color: #ff7a00;
    font-weight: bold;

}
