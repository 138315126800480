@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

.projects-wrapper {
  
  .progress-bar { 
    @include prefix(border-radius,10px 10px 10px 10px);
    @include prefix(animation, progress-bar 4s);
    background-color: rgba(0, 155, 202,1);
    display: block;
    width: 0px;
    }

  .row {
    margin: -14px;
  }

  [class^=col] {
    padding: 14px;
  }

  .text-greenlight{
    color: rgb(23, 255, 54) !important;
  }

  .text-green{
    color: rgb(3, 202, 30) !important; 
  }  

  .text-orange{
    color: $primary !important;
  }

  .text-red{
    color: rgb(255, 59, 48) !important;
  }

  .text-reddark{
    color: rgb(186, 10, 0) !important;
  }
}


section#proyek-page {
    // Animations
    .progress-bar { 
      @include prefix(border-radius,10px 10px 10px 10px);
      @include prefix(animation, progress-bar 4s);
      background-color: rgba(0, 155, 202,1);
      display: block;
      width: 0px;
    }
  
    .filters-wrapper {
      @include media-breakpoint-down(md) {
        padding: 50px 52px;
      }
      
      .filters {
  
        .wrapper-title {
          font-weight: 700;
          margin-bottom: 34px;
        }
  
        .filter {
          margin-bottom: 36px;
    
          .filter-title {
            font-weight: 600;
          }
    
          .filter-inputs {
            .filter-input {
              position: relative;
              margin: 10px 0;
              font-size: .9rem;
  
              &.filter-input-text label {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 8px;
                margin: auto 0;
                padding: 8px;
  
                +input {
                  padding-left: 46px;
                  font-size: .9rem;
                }
              }
            }
          }
        }
      }
    }
  
    .banners {
      margin-bottom: 30px;
  
      // .owl-stage {
      //   padding-left: 0 !important;
      //   margin-left: -50px;
      // }
  
      .item {
        padding: 40px 60px;
        border-radius: .5rem;
        overflow: hidden;
        position: relative;
        max-height: 200px;
        min-height: 200px;
        height: 200px;
  
        @media only screen and (max-width : 480px) {
          padding: 14px 45px;
        }
  
        .bg-img {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: -1;
        }
  
        h4 {
          max-width: 370px;
          margin-bottom: 14px;
        }
  
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  
    .sec-header {
      margin-bottom: 30px;
  
      h4 {
        margin-bottom: 6px;
      }
    }
  }