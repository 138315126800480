@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

section#total-biaya {
  padding: 0 0 80px;

  .container-section {
    padding: 0px 180px;

    @media (max-width: 480px) {
      padding: 0px;
    }
  }

  .text-title {
    font-weight: 700;
    line-height: 1.4;
    max-width: 650px;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-title-suku {
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 30px;
    text-align: center;
  }

  .text-suku {
    font-size: 1.1rem;
    color: #8e8e93;
    line-height: 1.6;
    text-align: center;
  }

  .text {
    font-size: 1.1rem;
    color: #8e8e93;
    max-width: 970px;
    line-height: 1.6;
  }

  .subtext {
    font-size: 1.1rem;
    color: $dark;
    max-width: 970px;
    line-height: 1.6;
    text-align: center;
  }

  .row {
    padding: 50px 0;
  }

  .text-header-table {
    text-align: center;
    font-weight: 700;
  }

  .text-green {
    color: $green;
  }

  .text-red {
    color: $danger;
  }

  .text-center {
    text-align: center;
  }

  td {
    h6 {
      font-weight: 600;
    }
  }


}