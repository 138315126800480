@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

.modal-content {
    border-radius: $border-radius !important;
}

.modal-body {
    &.modal-tkb-body {
        padding: 0 2rem 0 2rem !important;
    }
}

.modal-header {
    border: unset !important;
    &.modal-tkb-wrapper {
        padding: 2rem 2rem 0.9rem 2rem !important;
        .close {
            display: none;
        }
        .modal-title {
            margin: 0;
        }
    }
}

.text-body-modal {
    color: rgba(18, 18, 18, 0.6);
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.button-confirm {
}
