.img-bank{
width: 126px;
padding-right: 20px;
-webkit-width: 126px;
-webkit-padding-right: 20px;

          @media only screen and (max-width : 480px) {
              width: 100px;
              height: 30px;

              -webkit-width: 100px;
              -webkit-height: 30px;
          }
}

.title{
    padding-top: 10px;
        @media only screen and (max-width : 480px) {
            padding-top: 3px;
        }
}