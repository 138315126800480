@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

section#our-team {
  padding-bottom: 80px;
    padding-top: 60px;
    .team-wrapper {
      max-width: 1000px;
      margin: auto;
  
      .card-img-overlay {
        padding: 0;
        margin: 1.25rem;
      }
  
      .team-profile {
        background: #fff;
        border-radius: $border-radius;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        // padding: .25rem .5rem;
        padding: 1rem 1.15rem 1.15rem;
        height: 100%;
        max-height: 87px;
        transition: all 1s;
        overflow: hidden;
        text-overflow: ellipsis;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  
        &:hover {
          max-height: 400px;
        }
  
        .no-overflow {
          overflow: hidden;
          height: 100%;
        }
  
        .name {
          color: $primary;
          font-weight: 700;
          margin-bottom: 2px;
        }
  
        .title {
          color: $secondary;
          font-size: .9rem;
          font-weight: 700;
          margin-bottom: 12px;
        }
  
        .desc {
          color: $secondary;
          font-size: .9rem;
        }
  
        @include media-breakpoint-up(md) {
          padding: 1rem 1.15rem 1.15rem;
  
          .name {
            font-size: 1.1rem;
            margin-bottom: 8px;
          }
  
          .title {
            font-size: 1rem;
          }
        }
      }
    }
  }