@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

.tkb-carousel {
    .owl-theme .owl-dots {
        position: relative;
        text-align: left;
        display: flex;
        flex: 1;
        width: 100%;
        margin-top: 5px;
    }
    .owl-theme .owl-dots .owl-dot {
        flex: 1;
        @media only screen and (max-width: 991px) {
            flex: 0;
        }
    }
    .owl-theme .owl-dots .owl-dot span {
        border-radius: 4px;
        display: block;
        flex: 1;
        height: 3px;
        margin: 5px 2px;
        opacity: 0.5;
        min-width: 15px;
        background: gray 0% 0% no-repeat padding-box;
        opacity: 0.5;
    }
    .owl-theme .owl-dots .owl-dot.active span {
        border-radius: 4px;
        display: block;
        height: 3px;
        flex: 1;
        margin: 5px 2px;
        opacity: 0.5;
        min-width: 15px;
        background: #ff7a00 0% 0% no-repeat padding-box;
        opacity: 1;
    }
}
