@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

.project-detail {
  .card-table {
    background: #f2f2f2;
    border-radius: 8px;
    padding: 16px;
  }

  .table-pendanaan-custom {
    @media only screen and (max-width: 480px) {
      width: 260px;
    }
  }
  .table-asuransi-custom {
    @media only screen and (max-width: 480px) {
      width: 410px;
    }
  }

  .progress-bar {
    @include prefix(border-radius, 10px 10px 10px 10px);
    @include prefix(animation, progress-bar 4s);
    background-color: rgba(0, 155, 202, 1);
    display: block;
    width: 0px;
  }

  .project-img {
    width: 100%;
    max-width: 100%;
    border-radius: $border-radius;
    object-fit: cover;
    object-position: center;
    height: 400px;
  }

  .project-body {
    padding: 1rem 0;

    .card-title {
      font-weight: 600;
      font-size: 1.2rem;
      margin: 1.5rem 0;
      height: 52px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .progress {
      height: 16px;
      margin: 12px 0 16px;
      background: #ffe7d9;

      .progress-bar {
        border-radius: 0.5rem;
        width: 0;
        transition: width 3s ease;
        position: relative;
        text-align: right;
      }
    }

    .text-progress-bar {
      color: black;
      margin-left: 340px;

      @media only screen and (max-width: 480px) {
        color: black;
        margin-left: 160px;
      }
    }

    .label-info {
      color: rgba(1, 17, 24, 0.5);
      margin-bottom: 0;
    }

    .value-info {
      font-weight: 700;
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  .payment-method {
    font-weight: 400;
    margin-bottom: 0;
    font-size: 1rem;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    .card-body {
      padding: 1.25rem 1.85rem;

      .radio-wrapper {
        padding-left: 10px;
        padding-right: 23px;
      }

      .payment-logo {
        margin-left: auto;
        width: 100px;
      }
    }
  }
}
