/* Animation */

@mixin prefix ($property, $value,
$moz:    true,
$webkit: true,
$o:      false,
$ms:     true,
$spec:   true) {
@if $moz    {    -moz-#{$property}: $value; }
@if $webkit { -webkit-#{$property}: $value; }
@if $o      {      -o-#{$property}: $value; }
@if $ms     {     -ms-#{$property}: $value; }
@if $spec   {         #{$property}: $value; }
}

@keyframes progress-bar {
    0% { width: 0; }
  }
  @-webkit-keyframes progress-bar {
    0% { width: 0; }
  }
  @-moz-keyframes progress-bar {
    0% { width: 0; }
  }
  @-o-keyframes progress-bar {
    0% { width: 0; }
  }