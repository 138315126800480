// Body
$body-bg: #fff;
$body-color: #011118;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.4;

// Colors
$blue: #3490dc;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #03ca1e;
$teal: #4dc0b5;

//sector
$indigo: #2d3047;
$cyan: #6cb2eb;
$sea-green: #1b998b;
$raw-umber: #734a12;
$gray-asparagus: #465945;
$dark-coral: #cd5b45;
$celadon: #ace1af;
$dark-tan: #918151;
$friebriek: #b22222;
$yellow-green: #9acd32;
$dark-scarlet: #560319;
$rosy-brown: #bc8f8f;
$dark-golden: #b8860b;
$buff: #f0dc82;
$dark-spring-green: #177245;

$primary: #ff7a00;
$secondary: #8e8e93;
$success: #03ca1e;
$danger: #ff3b30;
$warning: #ff5a01;
$dark: #011118;
$second-dark: #25282b;
$border-radius: 0.5rem;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1340px,
);

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// Sweetalert2 Customization
$swal2-width: 28em;
$swal2-backdrop: rgba(#000, 0.75);
$swal2-success: $primary;
$swal2-success-border: rgba($primary, 0.3);
