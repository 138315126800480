.text-progress-bar {
  color: black;
  // margin-left: 180px;
}

.employee {
  margin-left: auto;
  float: right;
}

.link-text {
  color: black;
}

.text-alert {
  font-size: 13px !important;
  // line-height: 1.5 !important;
}

.wrapper-statistics{
  padding-inline: 18px;
  padding-block: 14px;
}