@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../sass/variables";
@import "../../../sass/mixins";

section#info-pinjam {
  position: relative;
  z-index: 1;
  padding: 60px 0;

  @include media-breakpoint-up(md) {
    padding: 0 0 60px;

    .floating-card {
      transform: translateY(-30%);
    }
    .info-list {
      margin-top: -30px;
    }
  }

  @media only screen and (max-width: 480px) {
    .whatsapp {
      position: fixed;
      z-index: 99999;
      top: 80%;
      left: 167px;
      width: 55%;
      padding-left: 45px;
      background-color: white;
      color: black;
      border-color: $secondary;
    }

    .alert-dismissible {
      padding-right: 0;
    }

    .alert-dismissible .close {
      left: 0;
    }
  }
}
