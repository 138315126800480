@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../sass/variables";
@import "../../sass/mixins";


section#detail-tiketing {

    h1 {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        color: #404040;
    }

    .card-primary {
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 24px;
        margin-top: 24px;
        width: 100%;
    }

    .card-chat {
        background: #FAF9F9;
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        border-radius: 8px;
        margin-top: 24px;
        width: 100%;
        padding: 24px 0;
        display: flex;
        justify-content: center;
        align-items: center;


    }

    .box-chat {
        width: 70%;
    }

    .chat-richtext {
        width: 100%;
        height: 176px;
        background-color: #fff;
        border: 1px solid #E8EDF1;
        border-radius: 4px;
    }

    .text-chat {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #757575;
        text-align: center;
    }

    .title-list {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #404040;
        margin: 4px 0;
    }

    .active-status {
        font-weight: 600;
    }

    .button-end {
        border: 2px solid #FF7A00;
        border-radius: 8px;
        padding: 8px 24px;
        background-color: #fff;
        color: $primary;
        cursor: pointer;
    }

    .avatar {
        width: 48px;
        height: 48px;
        border-radius: 9999px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        // line-height: 28px;
        color: #757575;
        border: 1px solid #F1E7DD;
        flex-shrink: 0;
    }

    .box-chat-user {
        background: #F5F5F5;
        border-radius: 8px;
        width: 100%;
        margin-right: 23px;
        padding: 8px;
    }

    .box-chat-admin {
        background: #FFFAF4;
        border: 1px solid #F1E7DD;
        border-radius: 8px;
        width: 100%;
        margin-left: 16px;
        padding: 8px;
    }

    .name-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #616161;
        margin: 0;
    }

    .date-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #616161;
        margin: 0;
    }

    .tox-tinymce {
        border-radius: 4px !important;
        border: 1px solid #E8EDF1 !important;
    }

    .chat-box-container {
        background: #FAF9F9;
        width: 100%;
        border: none;
        padding: 16px 100px;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.16);

        .box-chat-des {
            width: 100%;
            // background: #fff;
            border: 1px solid #E8EDF1;
            border-radius: 4px;
        }
    }

    .button-attachment {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border: 1px solid #E8EDF1;
        border-radius: 8px;
        cursor: pointer;
        position: relative;
    }

    .done-box-status {
        background: #3D7D40;
        border-radius: 8px;
        padding: 14px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: -0.011em;
        color: #FFFFFF
    }
}