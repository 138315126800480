@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../../../sass/variables";
@import "../../../../sass/mixins";

.projects-wrapper {
  .owl-item {
    @media only screen and (max-width: 480px) {
      left: -20px;
    }
  }
  .active {
    @media only screen and (max-width: 480px) {
      left: 0;
    }
  }

  .owl-nav {
    @media only screen and (max-width: 480px) {
      display: none;
    }
  }
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 58%;
    transform: translateY(-50%);
    font-size: 23px;
    color: #ffa569;
    position: absolute;
    border: 3px solid;
    // padding-left: 5px;
    border-radius: 70%;
    width: 31.8px;
    height: 32px;
    margin: -2.8rem;
    @media only screen and (max-width: 480px) {
      position: absolute;
      top: auto;
      transform: inherit;
      margin-top: 2px;
      margin: 8px;
      width: 28.8px;
      height: 29px;
    }
    @media only screen and (min-width: 540px) and (max-width: 540px) {
      display: none;
    }
  }

  .owl-prev {
    @media only screen and (max-width: 480px) {
      left: 5rem;
    }
  }

  .owl-next {
    right: 0;
    @media only screen and (max-width: 480px) {
      right: 5rem;
    }
  }

  .icons {
    font-size: 19px;
    color: #ffa569;
    top: -4px;
    position: relative;
    padding: 7px;
    @media only screen and (max-width: 480px) {
      font-size: 15px;
      padding: 6px;
      top: -7px;
    }
  }

  .owl-item .active {
    width: 305px;
    margin-right: -16px;
  }

  .progress-bar {
    @include prefix(border-radius, 10px 10px 10px 10px);
    @include prefix(animation, progress-bar 4s);
    background-color: rgba(0, 155, 202, 1);
    display: block;
    width: 0px;
  }

  .row {
    margin: -14px;
  }

  [class^="col"] {
    padding: 14px;
  }

  .text-greenlight {
    color: rgb(23, 255, 54) !important;
  }

  .text-green {
    color: rgb(3, 202, 30) !important;
  }

  .text-orange {
    color: $primary !important;
  }

  .text-red {
    color: rgb(255, 59, 48) !important;
  }

  .text-reddark {
    color: rgb(186, 10, 0) !important;
  }
}

.back-banner-fanded {
  position: absolute;
  top: 89px;
  left: 20.4px;
  background-color: #f52;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  width: 150px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  width: 20px;
  padding: 10px;
}

.banner-fanded {
  position: relative;
  display: unset;
  z-index: 2;
  left: -8px;
  border-radius: 8px 8px 8px 0px;
  top: 55px;
  height: 40px;
  background-color: #ff7a00;
  width: 150px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 8px;
  text-transform: capitalize;
}

.banner-grade {
  position: absolute;
  top: 145px;
  right: 19px;
  width: auto;
  height: 32px;
  padding: 4px 12px;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border-radius: 4px;
  @media only screen and (max-width: 480px) {
    top: 125px;
  }
}

.banner-img {
  height: 190px;
  @media only screen and (max-width: 480px) {
    height: 170px;
  }
}

.banner-grade-detail {
  width: 88px;
  height: 32px;
  padding: 4px 12px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
}

.head-name {
  font-size: 1rem !important;
}
.project-card {
  overflow: hidden;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.15);
  border: 0 !important;
  height: 100%;
  color: $dark;
  border-radius: 16px !important;
  @include transition(box-shadow 120ms linear);

  .progress-bar {
    @include prefix(border-radius, 10px 10px 10px 10px);
    @include prefix(animation, progress-bar 4s);
    background-color: rgba(0, 155, 202, 1);
    display: block;
    width: 0px;
  }

  &:hover {
    color: $dark;
    box-shadow: 0 0.25rem 0.75rem 2px rgba(0, 0, 0, 0.2);
  }

  .card-body {
    padding: 0.75rem;
    position: relative;

    .card-label {
      margin-bottom: 1.25rem;

      .card-title {
        font-weight: 700;
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
        // height: 48px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .owner-text {
        font-size: 0.9rem;
        margin-bottom: 0;
      }
      p {
        font-size: 0.8rem;
      }
    }

    .suku-bunga {
      font-size: 0.9rem;
      color: $secondary;
      margin-bottom: 0;
    }

    .bunga {
      font-weight: 700;
      background-color: #e5e5ea;
      padding: 5px 10px;
      border-radius: 8px;
      margin-left: 12px;
    }

    .row {
      margin: {
        left: -8px;
        right: -8px;
      }

      [class*="col"] {
        padding: {
          left: 8px;
          right: 8px;
        }
      }
    }

    .progress {
      height: 16px;
      margin: 12px 0 16px;
      background: #ffe7d9;

      .progress-bar {
        border-radius: 0.5rem;
        width: 0;
        transition: width 3s ease;
        position: relative;
        text-align: right;
      }
    }

    .text-information {
      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .label-info {
        // color: rgba(1, 17, 24, 0.5);
        margin-bottom: 0.15rem;
        font-size: 13px;
      }

      .value-info {
        font-weight: 600;
        margin-bottom: 0;
        font-size: 13px;

        &.small-text {
          font-size: 0.9rem;
        }
      }
    }
  }
}
