@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";
@import "../../sass/variables";
@import "../../sass/mixins";

.lender-nav {
  margin: {
    top: -8px;
    bottom: -8px;
  }

  .nav-item {
    margin: {
      top: 8px;
      bottom: 8px;
    }

    .nav-link {
      display: flex;
      align-items: center;
      color: $secondary;
      border-radius: 50px;
      padding: 0.6rem 1.5rem;
      @include transition(all 120ms linear);

      &:hover {
        background-color: rgba($secondary, 0.2);
      }

      &.active {
        font-weight: 600;
        color: $primary;
        background-color: rgba($primary, 0.2);
      }

      i {
        margin-right: 1.5rem;
      }
    }
  }
}

.alert-box {
  padding: 0.75rem 1.25rem;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #fff;
  background: $primary;
  font-size: 0.9rem;

  .btn {
    font-size: 0.9rem;
  }
}

.alert-box-reject {
  background: $red;
  justify-content: space-between;

  .btn {
    height: 40px;
    width: 140px;
  }
}

.card-statistic {
  height: 100%;

  i {
    font-size: 40px;
  }
}

.no-data {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary;
  margin: 40px 0;

  .icon-wrapper {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: rgba(142, 142, 147, 0.2);
    margin-bottom: 16px;

    i {
      font-size: 58px;
    }

    svg {
      width: 58px;
      height: 58px;
    }
  }
}

.btn-mulai-dana {
  border-width: 2px;
  font-weight: 600;
  min-width: 290px;
  padding: 0.75rem 1rem;
}

.btn-long {
  width: 100%;
  max-width: 200px;
}

.akun-wrapper {
  .card-body {
    padding: 1.25rem;

    @include media-breakpoint-up(md) {
      padding: 32px;
    }
  }

  .form-group {
    margin-bottom: 22px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 36px;
    }
  }

  hr {
    margin: {
      top: 22px;
      bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      margin: {
        top: 36px;
        bottom: 68px;
      }
    }
  }

  [type="submit"] {
    width: 100%;

    // @include media-breakpoint-up(md) {
    //   max-width: 50%;
    // }
  }
}

.chart-wrapper {
  padding: 2rem;

  @include media-breakpoint-up(md) {
    padding: 1rem;
  }
}

.sector-title {
  margin-bottom: 26px;
  font-weight: 700;
  font-size: 1.1rem;
}

.chart-legend {
  .legends {
    padding-left: 0;
    list-style-type: none;

    .legend {
      display: flex;
      align-items: center;

      .indicator {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50px;
        margin-right: 13px;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .progress-divider {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: {
      top: 1rem;
      bottom: 1rem;
    }
  }
}
